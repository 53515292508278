.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 1em;
}

.Table {
  justify-content: center;
  display: flex;
}

.Table-editor {
  float: 'left';
  min-width: 30vw;
  margin: 1em;
}

.FoodTable span {
  margin-right: 1em;
}

.FoodPrompt {
  margin: 2em;
}

.FoodPrompt-amount-picker {
  margin-top: 1em;
}

.FoodPrompt-amount {
  margin-top: 1em;
}

.FoodPrompt-amount input {
  margin-top: 0.5em;
}

table {
  width: 100%;
}

.SelectedFoods tfoot {
  margin-top: 10px;
  border-top: 4px solid black;
}
